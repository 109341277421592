/* eslint-disable react/jsx-key */
import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";
import PhotoGallery from "pages/Location/PhotoGallery";
import React, { lazy } from "react";

const Admin = lazy(() => import("../pages/Demo/Admin"));
const Companies = lazy(() => import("../pages/Demo/Companies"));
const Company = lazy(() => import("../pages/Demo/Companies/Company"));
const Tasks = lazy(() => import("../pages/Demo/Tasks"));
const Task = lazy(() => import("../pages/Demo/Tasks/Task"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const LocationType = lazy(() => import("../pages/LocationTypes/LocationType"));
const EventType = lazy(() => import("../pages/EventTypes/EventType"));
const NotificationType = lazy(() =>
  import("../pages/NotificationTypes/NotificationType")
);
const Language = lazy(() => import("../pages/Languages/Language"));
const City = lazy(() => import("../pages/Cities/City"));
const SelectCity = lazy(() => import("../pages/SelectCity/Cities"));

const Locations = lazy(() => import("../pages/Location/Locations"));
const Location = lazy(() => import("../pages/Location/Location"));
const Events = lazy(() => import("../pages/Events/Events"));
const Event = lazy(() => import("../pages/Events/Event"));
const Posts = lazy(() => import("../pages/Posts/Posts"));
const Post = lazy(() => import("../pages/Posts/Post"));
const PostGallery = lazy(() => import("../pages/Posts/PostGallery"));

const routes = [
  {
    path: "/",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/admin",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Admin />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/companies",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Companies />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/companies/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/select_city",
    exact: true,
    element: (
      <AuthorizedRoute>
        <SelectCity />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_company",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_location_type",
    exact: true,
    element: (
      <AuthorizedRoute>
        <LocationType />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_post",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Post />
      </AuthorizedRoute>
    ),
  },

  {
    path: "/create_event_type",
    exact: true,
    element: (
      <AuthorizedRoute>
        <EventType />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_event",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Event />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_notification_type",
    exact: true,
    element: (
      <AuthorizedRoute>
        <NotificationType />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_language",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Language />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_city",
    exact: true,
    element: (
      <AuthorizedRoute>
        <City />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/tasks",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Tasks />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/tasks/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_task",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },

  {
    path: "/locations",
    exact: true,
    element: (
      <AuthorizedRoute>
        <SelectCity />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/events",
    exact: true,
    element: (
      <AuthorizedRoute>
        <SelectCity />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/posts",
    exact: true,
    element: (
      <AuthorizedRoute>
        <SelectCity />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/locations/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Locations />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_location/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Location />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/locations/:cityUid/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Location />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/locations/:cityUid/:uid/edit",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Location />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/locations/:cityUid/:uid/gallery",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PhotoGallery />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/events/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Events />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_event/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Event />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/events/:cityUid/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Event />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/events/:cityUid/:uid/edit",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Event />
      </AuthorizedRoute>
    ),
  },

  {
    path: "/posts/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Posts />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/create_post/:cityUid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Post />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/posts/:cityUid/:uid",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Post />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/posts/:cityUid/:uid/edit",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Post />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/posts/:cityUid/:uid/gallery",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PostGallery />
      </AuthorizedRoute>
    ),
  },
];

export default routes;
