import React, { createContext, useContext, useEffect, useState } from "react";

export const CityContext = createContext();

export function CityProvider({ children }) {
  const [city, setCity] = useState(null);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    (async () => {
      setInitializing(true);
      try {
        const savedCity = await localStorage.getItem("city");

        if (savedCity !== null) {
          setCity(JSON.parse(savedCity));
        } else {
          setCity(null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setInitializing(false);
      }
    })();
  }, []);

  const changeCity = (c) => {
    setCity(c);
    localStorage.setItem("city", JSON.stringify(c));
  };

  return (
    <CityContext.Provider value={{ city, changeCity, initializing, setCity }}>
      {children}
    </CityContext.Provider>
  );
}

export function useCity() {
  const context = useContext(CityContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
