import { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import themes from "./themes";
import parseLanguages from "base-shell/lib/utils/locale";
import grants from "./grants";
import Loading from "material-ui-shell/lib/components/Loading/Loading";
import {
  defaultUserData,
  isGranted,
  isAnyGranted,
} from "rmw-shell/lib/utils/auth";
import { getDefaultRoutes } from "./getDefaultRoutes";

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: "AIzaSyD_QS_iEBWid0e1WPGmbc7RYIUSalHcwEM",
        authDomain: "gemeindenapps.firebaseapp.com",
        databaseURL:
          "https://gemeindenapps-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "gemeindenapps",
        storageBucket: "gemeindenapps.appspot.com",
        messagingSenderId: "29504482651",
        appId: "1:29504482651:web:0950d306b32b045d3dfe7e",
        measurementId: "G-SZ30V30219",
      },
    },
    dev: {
      initConfig: {
        apiKey: "AIzaSyC1W1T1pY-hacrLU9-J2gKeKf0SUHkFunY",
        authDomain: "gemeindenappsdev.firebaseapp.com",
        databaseURL:
          "https://gemeindenappsdev-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "gemeindenappsdev",
        storageBucket: "gemeindenappsdev.appspot.com",
        messagingSenderId: "807653865516",
        appId: "1:807653865516:web:8f2457e367538616bb3989",
        measurementId: "G-CPB0YC7T8D",
      },
    },
    devd: {
      initConfig: {
        apiKey: "AIzaSyC1W1T1pY-hacrLU9-J2gKeKf0SUHkFunY",
        authDomain: "gemeindenappsdev.firebaseapp.com",
        databaseURL:
          "https://gemeindenappsdev-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "gemeindenappsdev",
        storageBucket: "gemeindenappsdev.appspot.com",
        messagingSenderId: "807653865516",
        appId: "1:807653865516:web:8f2457e367538616bb3989",
        measurementId: "G-CPB0YC7T8D",
      },
    },
    firebaseuiProps: {
      signInOptions: ["google.com", "password"],
    },
  },
  googleMaps: {
    apiKey: "AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g",
  },
  auth: {
    grants,
    redirectTo: "/tasks",
    persistKey: "base-shell:auth",
    signInURL: "/signin",
    onAuthStateChanged: async (user, auth) => {
      const { getDatabase, ref, onValue, get, update, off } = await import(
        "firebase/database"
      );
      const db = getDatabase();

      try {
        if (user != null) {
          const grantsSnap = await get(ref(db, `user_grants/${user.uid}`));
          const notifcationsDisabledSnap = await get(
            ref(db, `disable_notifications/${user.uid}`)
          );

          const isAdminSnap = await get(ref(db, `admins/${user.uid}`));

          onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
            auth.updateAuth({ grants: snap.val() });
          });
          onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
            auth.updateAuth({ notificationsDisabled: !!snap.val() });
          });
          onValue(ref(db, `admins/${user.uid}`), (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() });
          });

          auth.updateAuth({
            ...defaultUserData(user),
            grants: grantsSnap.val(),
            notificationsDisabled: notifcationsDisabledSnap.val(),
            isAdmin: !!isAdminSnap.val(),
            isGranted,
            isAnyGranted,
          });

          update(ref(db, `users/${user.uid}`), {
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            providers: user.providerData,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            notificationsDisabled: notifcationsDisabledSnap.val(),
          });

          update(ref(db, `user_chats/${user.uid}/public_chat`), {
            displayName: "Public Chat",
            lastMessage: "Group chat",
            path: `group_chat_messages/public_chat`,
          });
        } else {
          off(ref(db));

          auth.setAuth(defaultUserData(user));
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  getDefaultRoutes: getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: "base-shell:locale",
    defaultLocale: parseLanguages(["en", "de", "ru"], "en"),
    onError: (e) => {
      //console.warn(e)

      return;
    },
  },
  menu: {
    MenuContent: lazy(() => import("../components/Menu/MenuContent")),
    MenuHeader: lazy(() => import("../components/Menu/MenuHeader")),
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultType: "light",
  },
  pages: {
    Dashboard: lazy(() => import("../pages/Dashboard")),
    PageNotFound: lazy(() => import("../pages/PageNotFound")),
  },
  components: {
    Menu: lazy(() => import("material-ui-shell/lib/containers/Menu/Menu")),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() => import("../containers/LayoutContainer")),
  },
};

export default config;
