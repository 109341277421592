import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  getDatabase,
  ref,
  query,
  limitToLast,
  get,
  onValue,
  set,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import Scrollbar from "material-ui-shell/lib/components/Scrollbar/Scrollbar";
import Page from "material-ui-shell/lib/containers/Page";
import { AppBar, Tabs, Tab } from "@mui/material";
import { useAuth } from "base-shell/lib/providers/Auth";
import { Collections, SaveAs, Add } from "@mui/icons-material";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Button from "@mui/material/Button";
import { CityContext } from "providers/city";

const path = "locations";

const PhotoGallery = () => {
  const { uid, action = "gallery" } = useParams();
  const { city: userCity } = useContext(CityContext);
  const { key: cityKey } = userCity || {};

  const [galleryData, setGalleryData] = useState([]);

  const getRef = useCallback(() => {
    return query(
      ref(getDatabase(), `locations/${cityKey}/${uid}/gallery`),
      limitToLast(50)
    );
  }, [uid, cityKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gallerySnapshot = await get(getRef());
        if (gallerySnapshot.exists()) {
          const galleryData = Object.values(gallerySnapshot.val());
          setGalleryData(galleryData);
        }
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      }
    };

    onValue(getRef(), () => {
      fetchData();
    });
  }, [uid, getRef]);

  const handleImagesUpload = async (e) => {
    try {
      const files = e.target.files;

      if (files.length > 0) {
        const storage = getStorage();
        const galleryRef = storageRef(
          storage,
          `/locations/${cityKey}/${uid}/gallery`
        );

        const newGalleryData = {};

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileRef = storageRef(galleryRef, file.name);

          await uploadBytes(fileRef, file);

          const downloadURL = await getDownloadURL(fileRef);

          newGalleryData[`item${i + 1}`] = {
            img: downloadURL,
            title: file.name,
          };
        }

        await set(ref(getDatabase(), `/locations/${cityKey}/${uid}/gallery`), {
          ...newGalleryData,
          ...galleryData,
        });

        const gallerySnapshot = await get(
          ref(getDatabase(), `/locations/${cityKey}/${uid}/gallery`)
        );
        if (gallerySnapshot.exists()) {
          setGalleryData(Object.values(gallerySnapshot.val()));
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const navigate = useNavigate();
  const intl = useIntl();

  const { auth } = useAuth();
  const { isGranted = () => false } = auth || {};
  const userUid = auth ? auth.uid : null;

  const tabs = ["edit", "gallery"];

  const secondBarContent = (
    <AppBar position="static">
      <Tabs
        value={tabs.indexOf(action)}
        onChange={(e, v) => {
          console.log("tab press", e.target.id, v);
          navigate(`/${path}/${cityKey}/${uid}/${tabs[v]}`, {
            replace: true,
          });
        }}
        indicatorColor="secondary"
        textColor="inherit"
        //variant="fullWidth"
        centered
      >
        <Tab icon={<SaveAs />} id="edit" />
        {uid && isGranted(auth, "read_container_tasks_files") && (
          <Tab icon={<Collections />} id="gallery" />
        )}
      </Tabs>
    </AppBar>
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = galleryData.map((item, index) => ({
    src: item.img,
    width: 1,
    height: 1,
    key: `photo-${index}`,
  }));

  return (
    <Page
      pageTitle={intl.formatMessage({
        id: "photo_gallery",
        defaultMessage: "Photo Gallery",
      })}
      tabs={userUid ? secondBarContent : ""}
      onBackClick={() => {
        navigate(-1);
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        <Scrollbar>
          {photos.length > 0 && (
            <Gallery
              photos={photos}
              onClick={openLightbox}
              direction={"column"}
              margin={8}
            />
          )}
        </Scrollbar>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>

        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          onChange={handleImagesUpload}
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button
            style={{
              position: "fixed",
              bottom: 15,
              right: 20,
              zIndex: 99,
              borderRadius: 30,
              width: 25,
              height: 60,
            }}
            variant="contained"
            color="secondary"
            component="span"
          >
            <Add />
          </Button>
        </label>
      </div>
    </Page>
  );
};

export default PhotoGallery;
