import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";
import React, { lazy } from "react";
import UnauthorizedRoute from "base-shell/lib/components/UnauthorizedRoute";

const SignIn = lazy(() => import("rmw-shell/lib/pages/SignIn/SignIn"));
const MyAccount = lazy(() => import("rmw-shell/lib/pages/MyAccount/MyAccount"));

const Users = lazy(() => import("rmw-shell/lib/pages/Users"));
const User = lazy(() => import("rmw-shell/lib/pages/Users/User"));

const Roles = lazy(() => import("rmw-shell/lib/pages/Roles"));
const Role = lazy(() => import("rmw-shell/lib/pages/Roles/Role"));

const EditMembers = lazy(() => import("rmw-shell/lib/pages/EditMembers"));
const EditAdmins = lazy(() => import("rmw-shell/lib/pages/EditAdmins"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));

const LocationTypes = lazy(() =>
  import("../pages/LocationTypes/LocationTypes")
);
const LocationType = lazy(() => import("../pages/LocationTypes/LocationType"));

const EventTypes = lazy(() => import("../pages/EventTypes/EventTypes"));
const EventType = lazy(() => import("../pages/EventTypes/EventType"));

const NotificationTypes = lazy(() =>
  import("../pages/NotificationTypes/NotificationTypes")
);
const NotificationType = lazy(() =>
  import("../pages/NotificationTypes/NotificationType")
);

const Languages = lazy(() => import("../pages/Languages/Languages"));
const Language = lazy(() => import("../pages/Languages/Language"));

const Cities = lazy(() => import("../pages/Cities/Cities"));
const City = lazy(() => import("../pages/Cities/City"));

const getDefaultRoutes = (appConfig) => {
  return [
    {
      path: "/signin",
      exact: true,
      element: (
        <UnauthorizedRoute>
          <SignIn redirectTo={appConfig?.auth?.redirectTo || "/"} />
        </UnauthorizedRoute>
      ),
    },
    {
      path: "/location_types",
      exact: true,
      element: (
        <AuthorizedRoute>
          <LocationTypes />
        </AuthorizedRoute>
      ),
    },

    {
      path: "/location_types/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <LocationType />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/event_types",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EventTypes />
        </AuthorizedRoute>
      ),
    },

    {
      path: "/event_types/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EventType />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/notification_types/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <NotificationType />
        </AuthorizedRoute>
      ),
    },

    {
      path: "/notification_types",
      exact: true,
      element: (
        <AuthorizedRoute>
          <NotificationTypes />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/languages",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Languages />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/languages/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Language />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/cities",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Cities />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/cities/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <City />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/edit_members/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditMembers />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/edit_admins/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <EditAdmins />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Roles />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/create_role",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/roles/:uid/:tab",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Role />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/my_account",
      exact: true,
      element: (
        <AuthorizedRoute>
          <MyAccount />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users",
      exact: true,
      element: (
        <AuthorizedRoute>
          <Users />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users/:uid",
      exact: true,
      element: (
        <AuthorizedRoute>
          <User />
        </AuthorizedRoute>
      ),
    },
    {
      path: "/users/:uid/:tab",
      exact: true,
      element: (
        <AuthorizedRoute>
          <User />
        </AuthorizedRoute>
      ),
    },
    {
      path: "*",

      element: (
        <AuthorizedRoute>
          <PageNotFound />
        </AuthorizedRoute>
      ),
    },
  ];
};

export { getDefaultRoutes };
