export const grants = [
  "create_company",
  "edit_company",
  "delete_company",
  "read_companies",
  "create_location_type",
  "read_location_type",
  "read_event",
  "create_event",
  "create_location",
  "read_location",
  "create_gallery_collection",
  "create_post",
  "create_notification_type",
  "read_notification_type",
  "create_language",
  "read_language",
  "create_city",
  "read_city",
];

export default grants;
